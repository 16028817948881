import "./style.css";
import left from "./img/left.webp";
import logo from "./img/logo.svg";
import wt from "./img/wt.svg";
import tg from "./img/tg.svg";
import { Fade, Flip, Zoom } from "react-reveal";
import { useEffect, useState } from "react";
import mobLeft from "./img/mobLeft.webp";
import mobLogo from "./img/mobLogo.svg";
import { Link, useNavigate } from "react-router-dom";
import Nav from "../Links/Nav";

export default function Header({ form, setForm, pop, setPop }) {
  const [play, setPlay] = useState(false);
  const Navigate = useNavigate();
  useEffect(() => {
    if (pop) {
      document.getElementsByTagName("body")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  }, [pop]);

  return (
    <div className="Header">
      {/* {pop && ( */}
      <div
        className="pop"
        style={pop ? { right: "0" } : { right: "-100vw" }}
        onClick={(e) => e.target.id != "puk" && setPop(false)}
      >
        <div className="popMenu" id="puk">
          <a href="#section1">
            <p>
              Каталог недвижимости <br /> в Аланье
            </p>
          </a>
          <a href="#section2">
            <p>Сравнение стоимости недвижимости в странах</p>
          </a>

          <a href="#section5">
            <p>Математика стоимости квартиры</p>
          </a>
          <a href="#fsection">
            <p>
              Сравнительный обзор <br /> Сочи vs Аланья
            </p>
          </a>
          <a href="#section3">
            <p>Преимущества недвижимости в Турции</p>
          </a>
          <a href="#state">
            <p>Прибыль от недвижимости в Турции</p>
          </a>
          <a href="#section7" id="aaaaaaaa">
            <p>Вопросы и ответы</p>
          </a>
          <Nav></Nav>
        </div>
      </div>
      {/* )} */}
      <div className="headerContent">
        <div className="leftHeader">
          <div className="ololol">
            {document.body.clientWidth > 640 ? (
              <video
                width="100%"
                height="100%"
                no-controls
                loop
                autoPlay
                muted
                poster="/left.webp"
              >
                <source src="./head.mp4" type="video/mp4" />
              </video>
            ) : (
              <img src={mobLeft} />
            )}
          </div>
        </div>
        <div className="headerRight">
          <Flip left>
            <img
              src={document.body.clientWidth > 640 ? logo : mobLogo}
              className="logo"
              alt=""
              onClick={() => Navigate("/")}
            />
          </Flip>
          <div className="topMenu">
            <p>Напишите</p>
            <a
              href="https://wa.me/905397746898"
              target="_blank"
              className="keks"
            >
              <img src={wt} alt="" />
            </a>
            <a
              href="https://t.me/CRPropertybot"
              target="_blank"
              className="keks"
            >
              <img src={tg} alt="" />
            </a>
            <a href="tel:+90 539 774 68 98" className="tel">
              +90 539 774 68 98
            </a>
            <div className="burgermenu" onClick={() => setPop(!pop)}>
              <div
                className="frist1"
                style={
                  pop
                    ? { transform: "rotate(45deg)", transformOrigin: "left" }
                    : {}
                }
              ></div>
              <div
                className="frist2"
                style={
                  pop && document.body.clientWidth > 640
                    ? {
                        width: "2.2vw",
                        transform: "rotate(-45deg)",
                        transformOrigin: "right",
                        marginTop: "-0.5vw",
                        left: "-0.6vw",
                        position: "relative",
                      }
                    : pop && document.body.clientWidth < 640
                    ? {
                        width: "8.2vw",
                        transform: "rotate(-45deg)",
                        transformOrigin: "right center",
                        marginTop: "-1.5vw",
                        left: "-2.22vw",
                        position: "relative",
                      }
                    : {}
                }
              ></div>
              <div className="frist3" style={pop ? { opacity: "0" } : {}}></div>
            </div>
          </div>
          <h1>
            Закрытая <br /> распродажа <br />
            <span> квартир в Аланье</span>
          </h1>
          <p className="zastr">Только январь-февраль</p>
          <div className="green">Прямые предложения от собственников</div>
          <div className="lineDiv">
            <div className="line"></div>
            <p>Оформим ВНЖ и гражданство</p>
          </div>
          <p className="ogr">Предложение ограничено</p>

          <div className="podr" onClick={() => setForm(true)}>
            Быть <br /> первым
          </div>
        </div>
      </div>
    </div>
  );
}
